.rentCard {
  box-shadow: 0px 2.98847px 4.48271px rgba(62, 73, 84, 0.04);
  border-radius: 15px !important;
  .paymentCard {
    background-color: #eeeeee;
    border-radius: 14.9424px;
    width: 67.24px;
    height: 67.24px;
    .paymentCardDetails {
      padding: 17px 18px 17px 18px;
    }
  }
  .card-details {
    margin-left: 2rem;
    .total-rent-Heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: black;
    }
    .rent-Duration-subHeading {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: black;
    }
  }
}

.addBankOrSchedulePayment {
  background-color: $white;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  padding: 40px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: calc(100vh - 230px);
  .row {
    background: #f4f7f7;
    border: 1.73315px dashed #c4c4c4;
    padding: 73px 122px 73px 123px;
    border-radius: 30px;
    height: 75%;
    // height: 376px;
    width: 50%;
    .col-md-12 {
      display: flex;
      justify-content: center;
      align-items: center;
      .add-prop {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        color: #000;
      }
      .button {
        background-color: $light-green-text-color;
        width: 206px;
        height: 50px;
        .button-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: white;
        }
      }
    }
  }
}

.headerButton {
  background-color: $light-green-text-color !important;
}

.addBankCard {
  background-color: $light-green-text-color !important;
  box-shadow: 0px 2.98847px 4.48271px rgba(62, 73, 84, 0.04);
  border-radius: 15px !important;
  align-items: center;
  height: 120px !important;

  .addBankHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: white;
    margin-top: 27px !important;
  }
}

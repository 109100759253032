.classsButton{
    background-color: #02AD88;
}

.buttonSettings{
    margin-top: 10px;
}

.buttonStyle{
    border-radius: 10px ;
    background-color: #02AD88 !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    align-items: center;
    padding: 13px 30px 13px 30px !important;
}

.cancelSubscription{
    background-color: $light-green-text-color !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    padding: 13px 23px 13px 23px !important;
}

.cancelButtonStyling{
    display: flex;
    position: absolute;
    right: 35px;
    bottom: 20px;
}
.bulk-actions {
  position: absolute;
  bottom: 4rem;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px #5a605f1a;
  box-shadow: 0px 0px 3px 0px #5a605f47;
  font-weight: 500;

  .text {
    padding: 8px 12px;
  }

  .options {
    display: flex;
    .status-payment-tab {
      background-color: #e6f7f3;
      border-radius: 6px;
      display: flex;
      gap: 6px;

      button {
        color: #274c44;
        &:hover {
          background-color: #02ad88;
          color: $white;
        }
      }
    }
    button {
      text-decoration: none;
      font-weight: 600;
      padding: 8px 20px;
    }
  }
}

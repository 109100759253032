* {
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 0.25rem solid #fff;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: $grey-20;
}

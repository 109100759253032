$font: 'Poppins', sans-serif;
$roboto-font: 'Roboto';
$lato-font: 'Lato';
$primarybg: #144c44;
$primary-btn-color: #009580;
$secondary-btn-color: #00695e;
$secondary-bg-color: #00695e;
$link-primary: #009580;
$link-secondary: #00695e;
$green-text-color: #144c44;
$light-green-text-color: #00695e;
$light-green-secondary-text-color: #02ad88;
$light-green-secondary-btn-color: #02ad88;
$error-danger-color: #fc2e53;
$pine-green-color: #00695e;
$light-green-bg-color: #edfffb;
$light-grey-color: #707579;
$secondary-grey-color: #717579;
$dark-grey-color: #565656;
$grey-text: #84818a;
$black-text-color: #202020;
$secondary-text-color: #171b1e;

$whisper-color: #eeeeee;

$pine-green-100: #112120;
$pine-green-90: #0e2e2b;
$pine-green-80: #0b3a36;
$pine-green-70: #084741;
$pine-green-primary: #00695e;
$pine-green-50: #398880;
$pine-green-40: #93b8b4;
$pine-green-30: #acc5c3;
$pine-green-20: #c5d3d1;
$pine-green-10: #d6dbdb;
$pine-green-0: #e9ecec;

$black: #000000;
$grey-100: #1b1c1c;
$grey-90: #282a2a;
$grey-80: #414545;
$grey-70: #5a605f;
$grey-primary: #78807f;
$grey-50: #838a89;
$grey-40: #a8acac;
$grey-30: #c6c8c7;
$grey-20: #d3d4d4;
$grey-10: #e5e6e6;
$grey-5: #f0f0f0;
$grey-0: #f7f7f7;
$white: #ffffff;

$red-100: #3e131f;
$red-90: #681228;
$red-80: #a61137;
$red-70: #c6113e;
$red-primary: #e51045;
$red-50: #e46f8c;
$red-40: #e3a3b3;
$red-30: #e2b5c1;
$red-20: #ebccda;
$red-10: #f5e5ec;

$yellow-100: #432a11;
$yellow-90: #723f0d;
$yellow-80: #b86006;
$yellow-70: #dc7003;
$yellow-primary: #ff8000;
$yellow-50: #f2ac66;
$yellow-40: #ebc49e;
$yellow-30: #ead1b8;
$yellow-20: #f1e0d0;
$yellow-10: #f8efe7;

$gradient: linear-gradient(90deg, #5091ff 0%, #30bdaf 100%);

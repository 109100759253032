.nameCard {
  // border: transparent !important;
  .userName {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
  }
  .imageClass {
    background-image: url('../../images/svgImages/imageBackgroundProfile.svg');
  }
}

.formHeadingtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  padding-bottom: 18px;
  color: rgba(0, 105, 94, 1);
}

.formTextarea {
  min-height: 130px !important;
}
.imageCard {
  border: transparent !important;
  .image {
    background-color: #d9d9d9 !important;
    border-radius: 30px !important;
    width: 60px;
    height: 60px;

    img {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
    }
  }
  .imageDetail {
    text-align: center;
    padding-top: 17px;
  }
  .NameDetail {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    margin-left: 17px !important;
  }
}


.settings {
    .profile {
      .imageUpload {
        margin-left: auto;
        width: max-content;
        .button{
          border: transparent !important;
        }
        .form-control {
          z-index: 1;
          opacity: 0;
        }
        .btn.btn-secondary.outline-btn {
          background-color: #f3f8f7;
          border: 0.833333px solid transparent !important;
          border-radius: 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #02AD88;
          padding: 15px 40px;
          min-width: 180px;
      }
      }
    }
  }

  .profile-form{
    .img-icon {
      position: absolute;
      right: 4px;
      top: 16px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-top: 35px !important;
      margin-right: 15px !important;
  }
}

.profile{
  .formLabel {
    color: $light-green-text-color !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    padding-bottom: 5px !important;
  }
}

.payout-landing-view {
  background-color: $white;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  padding-top: 36px;
  padding-bottom: 24px;
  display: flex !important;
  justify-content: center !important;
  height: calc(100vh - 230px);

  .payout-block-content {
    background: #f4f7f7;
    border: 1.73315px dashed #c4c4c4;
    border-radius: 30px;
    .payout-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 32px;
    }
    .add-prop {
      color: $green-text-color;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .bank-fee-note {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 70%;
    }
    .button {
      margin-bottom: 1rem;
    }
    .payout-type {
      color: $green-text-color;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 58px;
    }
    .payout-btn {
      padding: 10px 24px;
      border-radius: 5px !important;
      background-color: $light-green-secondary-btn-color !important;
      border-color: $light-green-secondary-btn-color !important;
      color: $white;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal !important;
    }
  }

  .addBankPayout {
    background-color: $light-green-text-color;
    .buttonText {
      padding: 16px 58px 16px 58px;
    }
  }
}
.addBankButton {
  position: absolute;
  right: 35px;
  bottom: 21px;
}
.addBankDetails {
  width: 100%;
  margin-top: 10px;
  background-color: #02ad88 !important;
  padding: 13px 70px 13px 70px;
  border-radius: 10px !important;
}
.amountDetails {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #ada7a7 !important;
    display: flex;
    justify-content: space-between;
  }
  p span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: 6rem;
  }
}

.addBankbuttonStyling {
  margin-top: 30px !important;
}

.scheduleButton {
  margin-top: 42px !important;
  background-color: #02ad88 !important;
  border: none !important;
  border-radius: 10px !important;
  line-height: 36px !important;
}
.schedule-payout-note {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $light-green-text-color;
}
.choose-bank-view {
  background: #f4f7f7;
  border: 2px dashed #c4c4c4;
  border-radius: 30px;
  .choose-bank-content {
    color: $green-text-color;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
  }
  .submit-btn.alert-modal-btn {
    padding: 10px 40px;
  }
}
.schedule-payout-wrapper {
  .schedule-payment-heading {
    color: $light-green-text-color;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.5px;
  }
  .selected-bank-name {
    color: $light-green-text-color;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.5px;
  }
  .new-payment-section {
    .add-new-block {
      border: 3px dashed $light-green-secondary-btn-color;
      width: 120px;
      height: 120px;
      cursor: pointer;
      background-color: #f4f7f7;
      border-radius: 10px;
    }
    .add-new-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
    .addBankButton {
      position: relative;
      bottom: 0;
      right: 0;
    }
  }
  .schedule-payout-block-parent {
    width: 165px;
    .schedule-payout-block {
      padding: 18px 12px;
      background-color: #02ad88;
      width: inherit;
      height: 165px;
      border-radius: 5px;
      .bank-text {
        color: $white;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.736px;
      }
    }
    .edit-payout-btn {
      border-radius: 4px;
      border: 1px solid #02ad88;
      color: #02ad88;
      font-family: Poppins;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 6px 20px;
      &:hover {
        background: #02ad88;
        color: $white;
      }
    }
    .delete-payout-btn {
      border-radius: 4px;
      border: 1px solid #02ad88;
      background: #02ad88;
      color: $white;
      font-family: Poppins;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 6px 20px;
      &:active {
        background: #02ad88;
        border: 1px solid #02ad88;
        color: $white;
      }
    }
  }
}

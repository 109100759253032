.btn.create-task-btn {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-radius: 8px;
  padding: 8px 20px;
  background: $secondary-btn-color;
  &:hover {
    background: $secondary-btn-color;
  }
  &:active {
    background-color: $secondary-btn-color !important;
  }
}

.ModalHour {
  padding: 8px;
  display: grid;
  gap: 10px;
  border-radius: 10px;
  max-width: 320px;
  z-index: 1000;
  background-color: $white;
  box-shadow:
    0px 4px 10px 0px #2c244029,
    0px 0px 2px 0px #2c24407a;

  .p-overlaypanel-content {
    padding: 0;
  }
}

.ModalHourInputs {
  gap: 10px;
}

.buy-notifications {
  .detail {
    .title {
      span {
        font-size: 32px;
        font-weight: 500;
        img {
          width: 38px;
          height: 38px;
        }
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  /* label {
    color: $pine-green-primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.5px;
  } */
  .required-astrick {
    color: #d02f44;
  }

  .notification-plan {
    padding: 1.25rem;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px #c8c6ce99;
    user-select: none;
    transition: all 200ms ease;

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: $pine-green-primary;
        display: flex;
        gap: 10px;

        input {
          display: none;
        }
      }

      .price {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: $pine-green-primary;

        .subtext {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0.5px;
          color: #5fa59d;
        }
      }
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      color: #84818a;
      line-height: 24px;
    }
  }
  .checked {
    box-shadow:
      0px 2px 6px 0px #c8c6ce99,
      inset 0 0 0 2px $pine-green-primary;
  }
}

.quantity-controller-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .quantity-controller {
    background-color: #02ad88;
    color: $white;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 2.75rem;
    border-radius: 10px;

    span {
      min-width: 4ch;
      max-width: 6ch;
      text-align: center;
    }
    button {
      background-color: white;
      border: none;
    }
  }
}

.total-amount-text {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: $black;
}
.total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  color: $black;
}
.card-information {
  margin-bottom: 3.75rem;
}

.checkout-input {
  margin-top: 8px;
  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $light-green-text-color;
  }
  .card-element {
    opacity: 0.6;
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 10px;
  }
}
.card-details-section {
  .sub-heading-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $grey-text;
  }
  .card-details {
    background: $white;
    border: 1px solid #eeeff3;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    padding: 12px;
    .card-number {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $black;
    }
    .p-button.edit-card-btn {
      background: $white;
      border: 1px solid $light-green-secondary-btn-color;
      border-radius: 5px;
      padding: 6px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: $light-green-secondary-text-color;
    }
    .card-exp-date {
      color: #b9b9b9;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.login-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background: $pine-green-primary;

  padding: 4rem 6rem;
  @media (max-width: 1367px) {
    padding: 2rem 3rem;
  }
  @media (max-width: 992px) {
    padding: 0;
  }

  .row,
  .col {
    margin: 0;
  }

  .login-wrapper {
    width: 100%;
    background-color: $white;
    border-radius: 2.5rem;
    display: flex;
    flex-direction: column;
    padding: 3rem 5rem 5rem;
    min-height: calc(100vh - 8rem);

    @media (max-width: 1367px) {
      padding: 2rem 3rem 3rem;
      min-height: calc(100vh - 4rem);
    }

    @media (max-width: 992px) {
      border-radius: 0;
      padding: 1.25rem;
      min-height: calc(100vh);
    }
  }
  .portal-tab {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 6px;
    border-radius: 8px;
    background-color: $pine-green-0;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0;
  }
}

.login-tab {
  position: absolute;
  top: 2rem;
  width: 100%;
  left: 0;
  &-container {
    background-color: #e6f7f3;
    padding: 0.4rem 1rem;
    border-radius: 8px;
    max-width: 435px;
    display: block;
    margin: auto;
  }

  &-button {
    outline: none;
    border: none;
    background: transparent;
    color: $primarybg;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    width: 50%;
    border-radius: 6px;

    &--active {
      background: #02ad88;
      color: white;
      box-shadow: 0 0 10px 0.25rem rgba(#02ad88, 0.25) !important;
    }
  }
}

.signup-container {
  width: 100%;
  min-height: 100vh;
  background: $pine-green-primary;

  padding: 4rem 6rem;
  @media (max-width: 1367px) {
    padding: 2rem 3rem;
  }
  @media (max-width: 992px) {
    padding: 0;
  }

  .row,
  .col {
    margin: 0;
  }

  .signup-wrapper {
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: 2.5rem;
    display: flex;
    flex-direction: column;
    padding: 3rem 5rem 5rem;

    @media (max-width: 1367px) {
      padding: 2rem 3rem 3rem;
    }

    @media (max-width: 992px) {
      border-radius: 0;
      padding: 1.25rem;
    }
  }
  .signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0;
  }
}

.tenantOverViewCard {
  background-color: $light-green-text-color !important;
  box-shadow: 0px 2.98847px 4.48271px rgba(62, 73, 84, 0.04);
  border-radius: 15px !important;
  .iconDetails {
    background-color: $white;
    border-radius: 14.9424px;
    width: 67.24px;
    height: 67.24px;
  }
  .property-card-details {
    margin-left: 1rem;
    .property-heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: $white;
    }
    .property-subHeading {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $white;
    }
  }
}

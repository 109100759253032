.header-container {
  background-color: $white;
  height: 64px;
  &.navbar {
    padding: 1.25rem 1.125rem;
  }
  .active-page-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $grey-90;
    text-transform: capitalize;
    user-select: none;
  }
  .calendar-icon {
    .cal-badge {
      background-color: #ff9f34;
    }
  }
  .profile-pic {
    background-color: #c4c4c4;
    border-radius: 11px;
    width: 28px;
    height: 28px;
    position: relative;
    img {
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  .user-name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: $secondary-text-color;
  }
  .user-role {
    font-style: normal;
    font-weight: 400;
    font-size: 10.6167px;
    line-height: 12px;
    color: $secondary-grey-color;
  }
}

.dropdown-list.dropdown-header {
  padding: 1rem 0;
  overflow: hidden;
  border-radius: 0.5rem;
  max-height: 20rem;
  .notifications-items {
    padding: 0 0.5rem;
    max-height: 18rem;
    overflow-y: auto;
    .notification-item {
      padding: 0.5rem;
      border-radius: 0.25rem;
      cursor: default;
      &:hover {
        background-color: $grey-5;
      }
      .close-icon {
        cursor: pointer;

        &:hover {
          filter: brightness(0);
        }
      }
      p {
        padding-right: 0.75rem;
      }
    }
  }
  .p-overlaypanel-content {
    padding: 0 !important;
  }
}

.bell-badge {
  position: absolute;
  padding: 4px;
  border-radius: 2px;
}
.bell-badge.no-read::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ff9f34;
  border-radius: 50%;
  top: 0;
  right: 0;
}

.page-right-content {
  width: 100%;
  transition: all 300ms ease;
}

@media (max-width: 992px) {
  .header-container.navbar {
    padding: 14px 14px 14px 64px;

    .back-icon {
      display: none;
    }
  }
}

@media (min-width: 993px) {
  .main-page:has(> .sidebar-wrapper > .collapsed) .page-right-content {
    width: calc(100% - 4.688rem);
  }

  .main-page:has(> .sidebar-wrapper > .collapsed .header:hover) .page-right-content {
    width: calc(100% - 6.688rem);
  }

  .main-page:has(> .sidebar-wrapper > .not-collapsed) .page-right-content {
    width: calc(100% - 270px);
  }
}

/* Aside menu styles */

.sidebar-wrapper {
  z-index: 100;
  top: 0;
  left: 0;
  transition: all 300ms ease;

  @media (min-width: 993px) {
    background-color: $white;
    box-shadow: 6px 0px 66px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    border-right: 2px solid #eeeeee;
    height: 100vh;
    width: 270px;
    overflow-y: auto;
    overflow-x: hidden;

    .mobile-sidebar {
      display: none;
    }
  }
  @media (max-width: 992px) {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;

    .sidebar {
      display: none;
    }
    .mobile-sidebar {
      display: flex;
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;
  height: 100%;
  padding: 1.25rem 0.625rem;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    .company-logo {
      margin-left: 0.625rem;
      img {
        height: 2rem;
      }
    }
  }
}
.main-menu {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $secondary-grey-color;
}

.nav {
  width: 100%;
}

.nav-item {
  width: 100%;
  padding: 0;
  .nav-link {
    display: flex;
    gap: 0.4rem;
    flex: 1;
    padding: 0.625rem 0.875rem;
    text-decoration: none;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $light-grey-color;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      color: $grey-90;
      font-weight: 400;
    }
    &:focus {
      color: $grey-90;
    }

    img {
      width: 24px;
      opacity: 0.7;
    }
  }
  &.active {
    .nav-link {
      font-weight: 600;
      background-color: $grey-5;
      color: $grey-90;
      img {
        opacity: 1;
      }
    }
  }
}
.logout-btn {
  background-color: $secondary-btn-color;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $white;
  padding: 14px 20px;
  &:hover {
    background-color: $secondary-btn-color;
  }
}

.sidebar-wrapper:has(.collapsed) {
  width: 4.688rem;
  .header {
    .collapse-button {
      transform: translateX(3rem);
      transition: all 400ms ease;
    }
  }
  &:has(.header:hover) {
    width: calc(4.688rem + 2rem);

    .header {
      .collapse-button {
        transform: translateX(0);
      }
    }
  }
  .nav-link {
    width: min-content;
    .nav-link-text {
      display: none;
    }
  }
}

.mobile-sidebar {
  height: 64px;
  width: 64px;
  .mobile-sidebar-button {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    padding: 14px;
    background-color: $white;
    height: 64px;
  }
}

.replaceButton {
  display: flex;
  justify-content: center;
  background-color: #e6f7f3 !important;
  color: $light-green-text-color !important;
  border: 0.833333px solid $light-green-text-color;
  span {
    padding: 8px 48px 5px 48px;
  }
}
.chechbox {
  .form-check-input:checked {
    background-color: #02ad88 !important;
    border-color: #02ad88 !important;
    height: 35px !important;
    width: 35px !important;
    border-radius: 20px !important;
  }
  .form-check-input[type='checkbox'] {
    // border-radius: 0.25em !important;
    border-color: #02ad88 !important;
    height: 35px !important;
    width: 35px !important;
    border-radius: 20px !important;
  }
}

.paymentCardSaveButton {
  display: flex;
  position: absolute;
  right: 35px;
  bottom: 21px;
}

.paymentLanding {
  background-color: $white;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  padding: 30px;
  display: flex !important;
  justify-content: center !important;
  height: calc(100vh - 230px);

  .tenant-add-bank {
    background: #f4f7f7;
    border: 1.73315px dashed #c4c4c4;
    border-radius: 30px;
    height: 100%;
    .add-payment-method-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 32px;
    }
    .p {
      width: 422px;
      height: 58px;
    }
    .paymentLanding-prop {
      font-size: 28px;
      font-weight: 400;
      line-height: normal;
      color: $green-text-color;
    }
    .paymentLanding-add-bank-note {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 60%;
    }
    .PaymentAddbutton {
      background-color: $light-green-text-color;
      border-radius: 10px;
      width: 207px;
      height: 50px;
      margin-right: 7px;
    }
  }
}

.cvvImageIcon {
  position: absolute;
  right: 4px;
  top: 16px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-top: 40px;
  margin-right: 15px;
}

.borderCheckedCustomClass {
  border: 1px solid #02ad88 !important;
  border-radius: 12px;
  padding: 30px 60px 30px 60px;
}
.borderCustomClass {
  border: 1px solid #979797 !important;
  border-radius: 12px;
  padding: 30px 60px 30px 60px;
}

.defaultMethodHeading {
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  margin-bottom: 15px !important;
}
.add-pymt-method-view {
  background: #f4f7f7;
  border: 1.73315px dashed #c4c4c4;
  border-radius: 30px;
  padding: 40px;
}

.subscription-wrapper {
  .subscription-buttons-wrapper {
    background-color: #e6f7f3;
    padding: 6px 10px;
    border-radius: 8px;
    gap: 12px;
  }
  .cart-icon {
    background: linear-gradient(151.84deg, #02ad88 10.96%, #5ed58d 113.67%);
    border-radius: 50px;
    width: 58px;
    height: 58px;
  }
  .subscription-card-heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  .subscription-card-sub-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    text-align: center;
    color: $black;
  }
  .btn.subscription-btn {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 6px 8px;
    color: $light-green-text-color;
    &:active {
      background-color: rgba(2, 173, 136, 0.1);
      border: 1px solid rgba(2, 173, 136, 0.1);
      color: $light-green-text-color;
    }
    .discount-badge {
      border-radius: 4px;
      border: 1px solid $light-green-secondary-text-color;
      padding: 2px 4px;
      color: $light-green-secondary-text-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    &.active {
      background-color: $light-green-secondary-btn-color;
      color: $white;
      .discount-badge {
        border: 1px solid$white;
        background: $white;
      }
    }
  }
  .subscription-plan-card {
    background: $light-green-bg-color;
    box-shadow: 0px 4px 4px #00000026;
    border-radius: 10px;
    padding: 2px;
    .plan-description {
      background: $light-green-bg-color;
      box-shadow: 0px 11px 13px rgba(0, 105, 94, 0.05);
      padding: 22px;
      .plan-name {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $light-green-text-color;
      }
      .plan-price {
        font-style: normal;
        font-weight: 400;
        font-size: 52px;
        line-height: 66px;
        color: $light-green-text-color;
      }
      .unit {
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 25px;
        color: $light-green-text-color;
        opacity: 0.6;
      }
    }
    &.selected {
      border: 2px solid $secondary-btn-color;
      box-shadow: none;
    }
    .plan-info {
      min-height: 190px;
      padding-left: 28px;
      padding-right: 20px;
      .plan-include {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: $secondary-btn-color;
      }
      .plan-charges {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: normal;
        color: $black;
      }
      .plan-type {
        background: $light-green-secondary-btn-color;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: $white;
        padding: 12px 50px;
        .quantity {
          min-width: 20px;
        }
        .quantity-btn {
          background: $white;
          box-shadow: 0px 10.4167px 26.0417px rgba(191, 21, 108, 0.05);
          border-radius: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 13px;
          color: $light-green-text-color;
          padding: 4px 6px;
        }
      }
    }
  }
  .submit-btn {
    background-color: $secondary-btn-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
    min-width: 190px;
    &:disabled {
      background-color: $secondary-btn-color;
    }
  }
  .outline-cancel-btn {
    background-color: rgba(2, 173, 136, 0.1);
    border: 1px solid $secondary-btn-color;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-btn-color;
    padding: 12px 48px;
    min-width: 190px;
    &:hover {
      background-color: rgba(2, 173, 136, 0.1);
      color: $secondary-btn-color;
    }
    &:active {
      background-color: rgba(2, 173, 136, 0.1) !important;
      color: $secondary-btn-color !important;
    }
  }
  .payment-secure-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    opacity: 0.5;
  }
}

.order-summary-wrapper {
  .heading-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: $light-green-text-color;
  }
  .sub-heading-text {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    color: $black;
    opacity: 0.5;
  }
  .summary-block-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $black;
    opacity: 0.7;
    &.selected-plan {
      line-height: 27px;
    }
  }
  .plan-summary-text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: $black;
    opacity: 0.7;
  }
  .form-control {
    opacity: 0.5;
    border: 1px solid #888b8e;
    border-radius: 4px;
  }
  hr {
    color: #cbccce;
    border: 2px solid;
  }
  .total-amount-text {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: $black;
  }
  .total-amount {
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 52px;
    color: $black;
  }
  .p-button.pay-now-btn {
    background: $secondary-btn-color;
    border-color: $secondary-btn-color;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: $white;
    display: block;
    &:hover {
      background: $secondary-btn-color;
      color: $white;
    }
    &:active {
      background: $secondary-btn-color !important;
      color: $white !important;
    }
  }
  .p-button.apply-coupon-now-btn {
    background: $light-green-secondary-btn-color;
    border-color: $light-green-secondary-btn-color;
    border-radius: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $white;
    display: block;
    &:hover {
      background: $light-green-secondary-btn-color;
      color: $white;
    }
    &:active {
      background: $light-green-secondary-btn-color !important;
      color: $white !important;
    }
  }
  .payment-secure-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    opacity: 0.5;
  }
  .plan-period {
    .plan-unselected-circle {
      background: #f3f8f7;
      height: 28px;
      width: 28px;
      border-radius: 50%;
    }
    .selected-plan {
      padding: 18px;
      background: $white;
      border: 1px solid #02ad88;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
      &.monthly-plan {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &.yearly-plan {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
    }
    .unselected-plan {
      padding: 18px;
      background: $white;
      border: 1px solid #888b8e;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
      &.monthly-plan {
        border-top: 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &.yearly-plan {
        border-bottom: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
    }
    .plan-period-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: $light-green-text-color;
    }
  }
  .checkout-input {
    margin-top: 8px;
    .form-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $light-green-text-color;
    }
    .card-element {
      opacity: 0.6;
      border: 1px solid #000000;
      border-radius: 4px;
      padding: 10px;
    }
  }
  .card-details-section {
    .sub-heading-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $grey-text;
    }
    .card-details {
      background: $white;
      border: 1px solid #eeeff3;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
      border-radius: 6px;
      padding: 12px;
      .card-number {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: $black;
      }
      .p-button.edit-card-btn {
        background: $white;
        border: 1px solid $light-green-secondary-btn-color;
        border-radius: 5px;
        padding: 6px 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: $light-green-secondary-text-color;
      }
      .card-exp-date {
        color: #b9b9b9;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
}

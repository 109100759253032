.chat-main-page {
  background: #f4f7f7;
}
.no-chats-container {
  height: calc(100vh - 230px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .no-prop {
    font-weight: 400;
    font-size: 28px;
    line-height: 48px;
    color: #144c44;
    margin: 8px 0 0;
  }
  .add-prop {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    color: #000;
  }
}

.common-body-container {
  .user-chats {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;

    @media (max-width: 992px) {
      margin-bottom: 0;
    }
  }
  .user-item {
    display: flex;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    gap: 24px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #eeeeee;
    }

    &.selected {
      background-color: #d8d8d8;
    }

    input {
      display: none;
    }

    .user-image {
      height: 64px;
      width: 64px;
      overflow: hidden;
      .avatar {
        width: 100%;
        height: 64px;
        border-radius: 16px;
        background-color: #b1b1b1;
      }
    }
  }
}

.chat-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  background-color: #eeeeee;
  display: flex;
  gap: 2px;
  box-sizing: border-box;

  .chat-list-container {
    padding: 20px;
    background-color: $white;
    height: 100%;
    z-index: 2;

    .chat-management {
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }

    .chat-list {
      overflow-x: none;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .searchbar-wrapper {
      padding: 0;
    }
  }
  .chat-view {
    background-color: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .chat-header {
      background-color: #f4f7f7;
      padding: 20px 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .addresse {
        display: flex;
        gap: 10px;
        align-items: center;

        .addresse-image {
          width: 32px;
          height: 32px;
          position: relative;

          .avatar {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            background: #b1b1b1;
          }

          .status {
            position: relative;
            top: -12px;
            left: 18px;
            z-index: 1;
            width: 18px;
            height: 18px;
            border-radius: 10px;
            border: 4px solid$white;
            background-color: #5ed48d;
          }
        }
      }

      @media (max-width: 992px) {
        padding: 1rem;
      }
    }

    .chat-content {
      padding: 30px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .message-sent,
      .message-received,
      .message-scheduled {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        gap: 6px;
        margin-bottom: 1rem;

        .message {
          max-width: 40vw;
          text-wrap: wrap;

          @media (max-width: 992px) {
            max-width: 80vw;
          }
        }

        .photo {
          width: 64px;
          height: 64px;
          border-radius: 16px;
          background-color: #b1b1b1;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .message-hour {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 4px;
          .message {
            padding: 1rem;
            border-radius: 10px;
            background-color: #f4f7f7;
          }
          .hour {
            color: #84818a;
          }
        }
      }

      .message-sent {
        flex-direction: row-reverse;

        .hour {
          text-align: right;
        }
      }
      .message-received {
        flex-direction: row;

        .unread-message {
          text-align: right;
          color: #fb3048;
          width: 100%;
          height: 1px;
          margin: 1.5rem 0;
          user-select: none;

          span {
            background-color: $white;
            padding: 6px;
            position: relative;
            top: -30px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }

      .message-scheduled {
        .message-hour {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .message {
            padding: 1rem;
            border-radius: 10px;
            background-color: $white;
            border: 1px solid #f4f7f7;
            width: max-content;
            color: #84818a;
          }
          .hour {
            color: #84818a;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 6px;
          }
          .scheduled-message-options {
            position: relative;
            top: -2rem;
            height: 0;

            button {
              box-shadow:
                0px 4px 8px 0px #5a605f1a,
                0px 0px 3px 0px #5a605f47;
              border-radius: 6px;
              padding: 0.75rem;
              background-color: $white;
              border: none;

              &:hover {
                background-color: #f4f7f7;
              }
            }
          }
        }
      }

      .date {
        text-align: center;
        color: #84818a;
        height: 1px;
        width: 100%;
        color: #171b1e;
        margin: 1.5rem 0 2rem;
        user-select: none;
        span {
          background-color: $white;
          border: 1px solid #eee;
          padding: 0 10px;
          position: relative;
          top: -28px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      @media (max-width: 992px) {
        padding: 2rem 1rem;

        ul {
          padding: 0;
        }
      }
    }

    .chat-footer {
      background-color: #f4f7f7;
      padding: 20px 30px;
      width: 100%;
      gap: 6px;
      display: flex;
      flex-direction: column;

      .chat-inputs {
        display: flex;
        gap: 6px;
        .message-input {
          width: 100%;
        }

        .message-button {
          .send-message-button {
            padding: 6px;
            border-radius: 8px;
            border: 1px solid #888b8e;
            background-color: $white;
            transition: opacity 200ms ease;
            &:disabled {
              opacity: 0.2;
            }
          }
        }
      }
      .schedule {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        .scheduled-messages {
          cursor: pointer;
          text-decoration: underline;
          color: #171b1e;
        }
        .select-schedule {
          position: relative;
          height: 1px;
          width: 1px;
          bottom: 0;
          left: -1rem;

          .schedule-options {
            position: relative;
            left: 8.5rem;
            display: flex;
            flex-direction: column;
            width: max-content;
            background-color: $white;
            border-radius: 10px;
            box-shadow:
              0px 4px 8px 0px #5a605f1a,
              0px 0px 3px 0px #5a605f47;
            padding: 8px;
            gap: 8px;
            transform: translateY(-110%);
            .schedule-option {
              padding: 4px 8px;
              text-decoration: none;
              color: #171b1e;
              cursor: pointer;

              &:hover {
                background-color: #f4f7f7;
              }
            }
          }
        }
      }
    }

    .no-chat-selected {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1;
      font-size: 20px;
      opacity: 0.8;
    }
  }
}
.chat-item {
  display: flex;
  padding: 0.7rem;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  gap: 24px;
  border-radius: 8px;
  cursor: pointer;

  /* &.new-message {
    background-color: #f4f7f7;
  } */
  &:hover {
    background-color: #f8f8f8;
  }
  .user-image {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    background-color: #d8d8d8;
    overflow: hidden;
    .avatar {
      width: 100%;
      height: 64px;
      background-color: #d8d8d8;
    }
  }
  .chat-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 4px;
    .heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .chat-name {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
      .time {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    .chat-last-message {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      overflow: hidden;
      height: 2rem;
    }
    .no-message {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #84818a;
    }
  }
}

.chat-item.active {
  background-color: #f4f7f7;
}

button.add-chat-button {
  padding: 6px;
}

.custom-schedule-modal {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex: 1;

  .ModalHourInputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

/* text styles */
.heading-text {
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 992px) {
  .chat-list-container {
    position: absolute;
    top: 0;
    left: 0;
    transition: left 200ms ease;
  }

  .chat-list-container.chat-selected {
    left: -100%;
  }
}

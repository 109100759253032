//common css
.form-card {
  background: $white;
  border-radius: 32px;
  .secutity-info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: $grey-text;
    .links {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $link-secondary;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        font-weight: 400;
      }
    }
  }
}
.informative-content {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $black-text-color;
  .link-content {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $link-secondary;
    text-decoration: none;
    &:hover {
      font-weight: 400;
    }
  }
}
.forgot-pwd-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $link-secondary;
  cursor: pointer;
  text-decoration: none;
  a {
    color: $link-secondary;
    text-decoration: none;
    &:hover {
      color: $link-secondary;
    }
  }
}
.divider {
  margin: 45px 0;
  border: 1px solid #dcdbdd;
}
.error-text {
  font-size: 12px;
  line-height: 18px;
  color: $error-danger-color;
}
.success-text {
  font-size: 12px;
  line-height: 18px;
  color: $light-green-text-color;
}
.info-text {
  font-size: 14px;
  text-align: center;
  color: $grey-text;
}
.floating-input-container {
  position: relative;
  .input-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $grey-text;
    position: absolute;
    top: 10px;
    left: 0px;
    pointer-events: none;
    transition: 0.2s ease all;
  }
  .form-control:not(:placeholder-shown) {
    outline: none;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid $secondary-btn-color;
    ~ .input-label {
      top: -8px;
      font-size: 12px;
      line-height: 17px;
      color: $grey-text;
    }
  }
  .form-control::placeholder {
    color: $white;
  }
  .form-control::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $white;
  }
  .form-control:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $white;
    opacity: 1;
  }
  .form-control::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $white;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $white;
  }
  .form-control {
    border: 0;
    border-bottom: 2px solid #dcdbdd;
    border-radius: 0;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: #000000;
    padding: 12px 0;
    &.filled {
      outline: none;
      background-color: transparent;
      box-shadow: none;
      border-bottom: 2px solid $secondary-btn-color;
      ~ .input-label {
        top: -8px;
        font-size: 12px;
        line-height: 17px;
        color: $grey-text;
      }
    }
    &:focus {
      outline: none;
      background-color: transparent;
      box-shadow: none;
      border-bottom: 2px solid $secondary-btn-color;
      ~ .input-label {
        top: -8px;
        font-size: 12px;
        line-height: 17px;
        color: $grey-text;
      }
    }
  }
}
.form-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  color: $black-text-color;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
}
.app-links {
  color: $link-primary;
  text-decoration: none;
  &:hover {
    color: $link-secondary;
  }
}

.img-icon {
  position: absolute;
  right: 18px;
  top: 13px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

//Properties
.occupancy-column div {
  justify-content: center;
}
.form-check {
  &-input {
    height: 18px;
    width: 18px;
    border: 2px solid $primary-btn-color;
    border-radius: 2px !important;

    &:checked {
      background-color: $primary-btn-color !important;
      position: relative;
      border-color: $primary-btn-color !important;
      outline: none;
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($primary-btn-color, 0.25) !important;
    }
  }
}

.form-input-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: $grey-text;
}
.privacy-terms {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 122.69%;
  letter-spacing: -0.005em;
  color: $dark-grey-color;
  position: absolute;
  bottom: 24px;
  right: 30px;
}
//loader css
.loader-wrapper {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: transparent;
  .loader-body {
    position: relative;
    height: 100%;
    .loader-content {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      .action-msg {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
      }
    }
    .loader-img {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
//toast-msg-style
.toast-message {
  background: $white !important;
  color: $dark-grey-color !important;
  padding: 1rem !important;
  border-radius: 10px !important;
  width: 350px;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.main-page {
  background: $grey-0;
  height: 100vh;
  display: flex;
  overflow: hidden;
  .main-page-content {
    height: calc(100vh - 64px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.filter-container {
  position: static;

  .toast {
    min-width: 380px;
    position: absolute;
    left: 90%;
    top: 2.5rem;
    z-index: 999;
    border-radius: 10px;
    padding: 1rem;
    background-color: $white;
  }

  .filter {
    &-title {
      font-size: 18px;
      color: $secondary-btn-color;
      font-weight: 500;
    }
    &-divider {
      display: block;
      margin: 0 auto 1rem auto;
      width: 100%;
      height: 1px;
      background-color: $whisper-color;
    }
    &-tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &-item {
        font-size: 12px;
        font-weight: 500;
        background-color: $whisper-color;
        border-radius: 4px;
        padding: 0.3rem 0.8rem;
        width: fit-content;
        margin-right: 0.8rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: $grey-text;
          font-size: 14px;
        }
      }
    }
    &-group {
      font-size: 14px;
      color: $black;
      font-weight: 500;
    }
    &-footer {
      padding-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    &-item {
      margin-bottom: 1rem;
    }
    &-btn {
      height: 36px;
      font-size: 14px;
      min-width: 80px;
      border-radius: 8px;

      &--active {
        background-color: $secondary-btn-color;

        &:hover {
          background-color: $secondary-btn-color;
        }
      }
    }
  }
}

//searchbar style
.searchbar {
  display: grid;
  grid-template-columns: 90% 10%;
  position: relative;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.3rem;
    min-height: 38px;
    max-width: 36px;
  }
}

.select-input {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 2.5rem;
  bottom: 1.04rem;
}

.search-clear {
  position: absolute;
  right: 1rem;
  bottom: 0.7rem;
}

.searchbar-container {
  border-radius: 8px;
  position: relative;
  border: 1px solid $grey-20;

  &--bg {
    background-color: $white;
  }

  .search-icon {
    align-items: center;
    position: absolute;
    left: 10px;
    top: 0;
    display: flex;
    right: 15px;
    height: 100%;
    width: fit-content;
    img {
      height: 18px;
    }
  }
  .form-control {
    border-radius: 8px;
    border: 0;
    padding-left: 35px;
    &:focus {
      outline: none;
      background-color: transparent;
      box-shadow: none;
    }
    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 10px;
      color: $grey-40;
    }
  }
}
//utilities
.cursor-pointer {
  cursor: pointer;
}
.card.propertyM-card {
  background: $white;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  .card-body {
    padding: 28px;
  }
}

//no-listing-view
.no-list-view {
  padding: 50px;
  height: calc(100vh - 230px);
  display: flex;
  justify-content: center;
  height: 100%;
  .col-md-12 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-prop {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $grey-90;
    margin-top: 2rem;
  }
  .add-prop {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $grey-90;
    margin-top: 1rem;
  }
}

.form-control,
.p-inputtext {
  padding: 12px 18px;
  border: 1px solid #888b8e;
  border-radius: 10px;
  &:focus {
    box-shadow: unset;
    border: 1px solid $light-green-text-color;
  }
}
.input-info {
  position: absolute;
  top: 12px;
  right: 32px;
  color: #3e3e3e;
  font-weight: 500;
}

.card.form-card {
  border: none;
  background: $white;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  padding: 0;
  min-height: calc(100vh - 230px);
  .card-header {
    background-color: $link-secondary;
    color: $white;
    font-size: 28px;
    line-height: 42px;
    font-weight: 500;
    padding: 15px 3rem;
    background-image: url('../images/card_header_masking.png');
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
  }
  .card-body {
    padding: 20px 3rem 32px 3rem;

    textarea.form-control {
      min-height: 250px;
    }
    &::placeholder {
      color: $grey-text;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-select {
      background-image: url('../images/svgImages/down_icon.svg');
    }
    .form-select:focus {
      box-shadow: none;
    }
    .form-select-lg {
      border: 1px solid #888b8e;
      border-radius: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $grey-text;
      &:focus {
        box-shadow: unset;
        border: 1px solid $light-green-text-color;
      }
    }

    .submit-btn {
      background-color: $secondary-btn-color;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
      min-width: 190px;
      &.payout-submit-btn {
        background-color: $light-green-secondary-btn-color;
      }
    }
    .outline-cancel-btn {
      background-color: rgba(2, 173, 136, 0.1);
      border: 1px solid $secondary-btn-color;
      border-radius: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-btn-color;
      padding: 12px 48px;
      min-width: 190px;
      &:hover {
        background-color: rgba(2, 173, 136, 0.1);
        color: $secondary-btn-color;
      }
      &:active {
        background-color: rgba(2, 173, 136, 0.1) !important;
        color: $secondary-btn-color !important;
      }
    }
    .features-items {
      .feature-text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $secondary-text-color;
        text-transform: capitalize;
      }
    }
    .detail {
      color: $link-secondary;
      font-size: 18px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.5px;
    }
    .list-inline-item {
      margin-top: 8px;
    }
    .list-inline-item:not(:last-child) {
      margin-right: 2rem;
    }
    .detail-item {
      margin-left: 10px;
      p {
        margin: 0;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        span {
          color: $link-secondary;
        }
      }
      .property-detail {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 195px;
      }
    }
    .pi-ellipsis-h {
      color: $white;
    }
  }
}
//settings payout form card style
.settings .card.form-card {
  min-height: calc(100vh - 190px);
}
//list table style
.data-list {
  .card.form-card {
    .card-body {
      padding: 6px 0 20px 0;
    }
  }
}

.datatable-listing {
  .p-datatable-table > thead > tr {
    th {
      background: $grey-5;
      font-style: normal;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: $secondary-text-color;
      padding: 0.75rem;
      white-space: nowrap;
    }
  }
  .p-checkbox {
    align-items: center;
  }
  .p-checkbox .p-checkbox-box {
    border: 2px solid $grey-30;
    border-radius: 2.23265px;
    width: 15px;
    height: 15px;
  }
  .p-datatable .p-datatable-tbody > tr {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    background-color: $white;
    box-shadow: 0px -1px 0px 0px $grey-5;

    td {
      padding: 0.75rem;
      white-space: nowrap;
    }

    &:hover {
      cursor: pointer;
      background-color: $light-green-bg-color;
    }
  }
  .occupancy-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .p-progressbar {
      background: $grey-5;
      width: 100%;
      height: 8px !important;
      border-radius: 8px;
      .p-progressbar-value {
        background: $pine-green-primary;
        border-radius: 8px;
      }
    }
    .occupancy-percentage {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 12px;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $secondary-btn-color;
    background: $secondary-btn-color;
  }
  .pagination-section {
    .total-records {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: $light-grey-color;
      .list-records {
        color: $light-green-secondary-text-color;
      }
    }
    .pagination-container {
      background: $white;
      border: 1px solid #ececee;
      border-radius: 4px;
      .p-paginator .p-paginator-pages .p-paginator-page {
        font-family: $lato-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #110f24;
      }
      .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        font-family: $lato-font;
        background: $light-green-secondary-text-color;
        border-radius: 4px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $white;
      }
      .p-paginator .p-paginator-prev {
        color: #110f24;
        &:disabled {
          color: rgba(17, 15, 36, 0.4);
        }
        .p-paginator-icon {
          font-size: 24px;
        }
      }
      .p-paginator .p-paginator-next {
        color: #110f24;
        .p-paginator-icon {
          font-size: 24px;
        }
        &:disabled {
          color: rgba(17, 15, 36, 0.4);
        }
      }
    }
  }
}
//image-gallery
.gallery {
  max-width: 100%;
  position: relative;
  .next-btn {
    background: $white;
    box-shadow: 0px 7px 34.3px rgba(94, 132, 194, 0.31);
    border-radius: 21.7px;
    padding: 9px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 60%;
    right: 4%;
    transform: translate(50%, -50%);
    &::before {
      font-size: 18px;
      color: $light-green-secondary-btn-color;
    }
  }
  .slick-slide {
    width: 250px !important;
    margin-right: 10px;
    .slide {
      height: 140px;
      border-radius: 10px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }

  .card-body-layout {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.count-card.card {
  background: $white;
  border: 1px solid $grey-20;
  padding: 20px;
  border-radius: 20px;
  max-width: 356px;
  .card-body {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  .content {
    flex: 1 1;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: $grey-50;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .count {
    color: $grey-90;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}

.account-card {
  margin: auto;
  display: block;
  border-radius: 12px !important;
  .card-heading {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
  .card-body {
    padding: 30px 24px 16px 24px !important;
  }
  color: #00695e;
  box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);
  border: 1px solid #02ad88;
  &.active {
    border-radius: 12px !important;
    color: white;
    background: #00695e;
    border-width: 0px;
    // .edit-button {
    //   // border-color: white !important;
    //   // padding: 6px 16px 6px 16px;
    // }
  }
  p {
    color: inherit;
  }

  .edit-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    width: 66px;
    height: 21px;
    padding: 6px 16px 6px 16px;
  }

  .card-number {
    div {
      line-height: 1;
      margin-right: 1rem;
      &:last-child {
        line-height: 20px;
        font-size: 18px;
        margin-right: 0;
      }
    }
  }

  .edit-button span.border {
    border-color: #02ad88 !important;
    cursor: pointer !important;
  }
  .card-footer {
    background-color: #02ad88;
    border-radius: 0px 0px 12px 12px !important;
    p {
      color: white !important;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    }
    .info {
      opacity: 0.5;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
.dialog {
  .dialogHeader {
    height: 75px !important;
    width: 100%;
  }
  .p-dialog-header {
    display: none !important;
  }
  .p-dialog-content {
    padding: 0px !important;
  }
  .checkedDetail {
    background-color: #02ad88 !important;
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-content {
    .payment-message {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 1.5;
      color: #000000;
    }
    .regards {
      display: flex;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      color: $grey-text;
      .redirect-Link {
        cursor: pointer !important;
      }
    }
  }
  .cardBody {
    align-items: center !important;
  }

  .overViewHeading {
    font-size: 24px !important;
    line-height: 1.5 !important;
    font-weight: 500;
    font-style: normal;
    color: $black-text-color;
  }
}
.doughnutLayout {
  width: 86px;
  height: 86px;
}
.payment-doughnutCard {
  border-radius: 15px !important;
  .headings {
    .main-heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #000000;
    }
    .sub-heading {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #000000;
    }
  }
  .count-ring {
    background-color: $white !important;
    border-radius: 12px;
  }
  .Detail {
    background-color: $whisper-color;
    padding: 22px 20px 21px;
    border-radius: 12px;
  }
}
.card.overview-chart-card {
  background: $white;
  box-shadow: 0px 2.98847px 4.48271px rgba(62, 73, 84, 0.04);
  border-radius: 15px;
}
.card.green-card {
  background: $pine-green-50;
  color: $white;
  border-radius: 15px;
  box-shadow: 0px 2.98847px 4.48271px rgba(62, 73, 84, 0.04);

  .caption {
    color: #d6dbdb;
  }
  .chart {
    svg > rect {
      fill: transparent;
    }
  }
}

.dropdown-toggle,
.dropdown-toggle:hover {
  background-color: none !important;
  border: none !important;
}

.gear-icon-dropdown {
  .dropdown-toggle::after {
    content: none !important;
  }
}
.p-button.confirm-accept-btn {
  background-color: $primary-btn-color;
  border-color: $primary-btn-color;
  border-radius: 4px;
  color: $white;
  &:enabled:hover {
    background-color: $primary-btn-color;
    border-color: $primary-btn-color;
    color: $white;
  }
}
.detail-card-ellipsis-dropdown {
  .dropdown {
    .btn-secondary {
      background: unset;
      border: unset;
      padding: 0;
      &:active {
        background: unset;
        border: unset;
      }
    }
  }
}

.modal {
  background-color: #071514c2;
}
.modal-content {
  border-radius: 16px !important;
  overflow: hidden;
}
.common-alert-modal {
  .modal-header-container {
    background-color: $white;
    font-size: 28px;
    line-height: 42px;
    font-weight: 500;
    padding: 1.5rem 1.5rem 0;
    padding-bottom: 0;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    flex-direction: column;
  }
  .modal-header-container:has(> .close-icon) {
    justify-content: flex-end;

    .close-icon {
      width: 36px;
      height: 36px;
      position: relative;
      right: 0;

      img {
        padding: 10px;
      }
    }
  }
  .common-body-container {
    box-shadow: 0px 3.08054px 4.62081px rgba(62, 73, 84, 0.04);
    border-radius: 0 0 9px 9px;
    padding: 0 1.5rem;

    .heading-text {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
    }

    .icon-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .icon {
        border-radius: 50%;
        padding: 13px;
        background: $gradient;
        box-shadow: 0px 0px 0px 4px rgba(48, 189, 175, 0.16);
      }
    }
    .message-text {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      color: $black;
    }
    .sub-heading-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $grey-text;
      span {
        color: $light-green-secondary-text-color;
      }
    }
    .redirect-link {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-decoration-line: underline;
      color: $light-green-text-color;
    }

    .modal-button {
      border-top: 1px solid $grey-20;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1.5rem;
      width: 100%;
      background: $white;

      @media (max-width: 992px) {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        button {
          width: 100%;
        }
      }
    }
  }
}

.delete-modal .modal-body-content {
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
}

.custom-recurrence-modal {
  .header-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: $grey-90;
  }
  .react-datepicker-wrapper {
    input {
      width: 100%;
      border-radius: 10px;
      border: 1px solid #dcdbdd;
      background: $white;
      padding: 16px;
      color: #84818a;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .form-check-input[type='radio'] {
    border-radius: 50% !important;
  }

  .monthly-recurrence-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;

    .monthly-recurrence-input {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #888b8e;
      border-radius: 8px;
      padding: 6px;
      font-size: 14px;
      font-weight: 600;
      user-select: none;
      cursor: pointer;

      &:hover {
        background: $whisper-color;
      }

      &:has(.form-check-input:checked) {
        background: $primary-btn-color;
        color: $white;
      }

      .form-check-input {
        display: none;
      }
    }
  }
}
.tree-select .p-treeselect {
  border-radius: 10px;
  border: 1px solid #888b8e;
  background: $white;
  padding: 6px 12px;
  width: 100%;
  color: #84818a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  .p-treeselect-trigger {
    background-image: url('../images/svgImages/down_icon.svg');
    background-repeat: no-repeat;
    background-position: 100%;
  }
  .pi-chevron-down::before {
    content: '';
  }
  &:not(.p-disabled):hover {
    border: 1px solid #888b8e;
  }
  &:not(.p-disabled):focus {
    border: 1px solid #888b8e;
    box-shadow: unset;
  }
  .p-treeselect-token {
    border-radius: 4px;
    background: #eee;
    padding: 4px 6px;
    color: #171b1e;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.p-treeselect-items-wrapper {
  .p-checkbox {
    .p-checkbox-box {
      border: 2px solid #84818a;
      &:hover {
        border: 2px solid #84818a;
      }
      &.p-highlight {
        border-color: $primary-btn-color;
        background: $primary-btn-color;
        &:hover {
          border-color: $primary-btn-color;
          background: $primary-btn-color;
        }
      }
    }
  }
  .p-tree {
    color: #000 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    .p-tree-container .p-treenode .p-treenode-content {
      padding: 4px 0px;
      &:hover {
        background-color: #eee;
        border-radius: 8px;
      }
    }
    .p-tree-container .p-treenode .p-treenode-content.p-highlight {
      background-color: #eee;
      border-radius: 8px;
    }
  }
}
.tooltip {
  opacity: 1 !important;
  width: 300px;
  inset: auto auto 0 75px !important;
  border-radius: 5px;
  border: 1px solid rgba(0, 105, 94, 0.2);
  background: $white;
  box-shadow:
    0px 2px 6px 0px rgba(200, 198, 206, 0.25),
    -1px 1px 4px 0px rgba(200, 198, 206, 0.4);
  .tooltip-inner {
    max-width: 300px;
    width: 100%;
    background: $white;
    color: #000;
    padding: 10px;
    text-align: left;
    font-size: 14px;
  }
  .tooltip-arrow {
    display: none !important;
  }
}
.react-datepicker__header {
  background-color: $white !important;
  border: none !important;
  margin-top: 10px;
}
//disabled slick prev/next btns
.slick-prev:before {
  content: unset !important;
}
.slick-next:before {
  content: unset !important;
}

// notification cards style

.notification-card {
  border-radius: 15px !important;
  .headings {
    .main-heading {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      text-align: left;

      color: #000000;
    }
    .sub-heading {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #84818a;
    }
  }
  .Detail {
    background-color: $whisper-color;
    padding: 13px 25px;
    border-radius: 12px;
    font-size: 28px;
    font-weight: 500;
  }
}

/* Form styles */

.form-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #282a2a;
  .required-astrick {
    color: #d02f44;
  }
}

.form-info-text {
  color: #84818a;
  font-size: 12px;
  font-weight: 400;
  user-select: none;

  a {
    color: #171b1e;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
}

.payment-secure-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  user-select: none;
}

/* text styles */

h1 {
  //styleName: Product/H1;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
}

h2 {
  //styleName: Product/H2;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
}

h3 {
  //styleName: Product/H3;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

p {
  //styleName: Product/Text/Regular;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.caption {
  //styleName: Product/Caption/Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $grey-primary;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 600;
}

.underlined {
  text-decoration: underline;
}

.balanced {
  text-wrap: balance;
}

h2.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: $grey-90;
}

/* Detail view */

.task-details {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: $white;
  box-shadow: -1px 0px 20px 0px #e0e0e0;
  overflow: hidden;
  z-index: 1000;
  transition: all 300ms ease;

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    padding: 1.5rem 0;
    color: $grey-90;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: $grey-90;
  }
  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $grey-primary;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $grey-90;
  }

  header {
    display: flex;
    width: 100%;
    height: 4rem;
    justify-content: space-between;
    padding: 1rem 1rem 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    img {
      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .details {
    min-width: 540px;
    padding: 0 2rem;
    height: calc(100vh - 4rem);
  }

  @media (max-width: 768px) {
    .details {
      min-width: 100%;
      padding: 0 1rem 1rem;
      overflow-y: auto;
    }
  }
}

.task-details.opened {
  width: 600px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.task-details.closed {
  width: 0;
  padding: 0 !important;
}

.details-view {
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }

  legend {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1f1f20;
  }

  .gallery .slick-slide .slide img {
    width: 170px !important;
    height: 170px;
    object-fit: cover;
  }
}

/* badge */

.status-badge {
  width: auto;
  height: max-content;
  padding: 0.25rem;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.status-badge.p-badge-success {
  background-color: $pine-green-30 !important;
  color: $pine-green-primary !important;
}
.status-badge.p-badge-warning {
  background-color: $yellow-40 !important;
  color: $yellow-70 !important;
}
.status-badge.p-badge-danger {
  background-color: $red-40 !important;
  color: $red-70 !important;
}

.badge-list {
  background-color: $grey-5;
  color: $grey-80;
  border-radius: 6px;
}

.badge-gradient {
  background: $gradient;
  color: white;
  border-radius: 6px;
}

/* Create-edit forms styles */
form {
  padding-bottom: 5rem;
  fieldset {
    border: none;
    margin-bottom: 2rem;
  }
  legend {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $black;
    margin-bottom: 1.25rem;
  }

  .form-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $grey-90;

    .required {
      color: $red-primary;
    }
  }

  .form-control {
    flex: 1;
    padding: 0.75rem 1rem;
    background-color: $white;
    color: $grey-90;
    border: 1px solid $grey-20;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &.filled {
      outline: none;
      ~ .input-label {
        top: -8px;
        font-size: 12px;
        line-height: 17px;
        color: $grey-text;
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
      ~ .input-label {
        top: -8px;
        font-size: 12px;
        line-height: 17px;
        color: $grey-text;
      }
    }
  }

  .p-calendar {
    width: 100%;

    .p-inputtext {
      border-radius: 10px 0 0 10px;
      border: 1px solid $grey-20;
      border-right: none;
      outline: none;
      box-shadow: none;
    }
    .p-button {
      border-radius: 0 10px 10px 0;
      background-color: $white;
      border: 1px solid $grey-20;
      border-left: none;

      svg path {
        fill: $grey-80;
      }
    }

    &:focus {
      .p-inputtext {
        border: 1px solid $grey-primary;
      }
      .p-button {
        border: 1px solid $grey-primary;
      }
    }
  }

  .form-select {
    background-image: url('../images/svgImages/down_icon.svg');
  }
  .form-select:focus {
    box-shadow: none;
  }
  .form-select-lg {
    border: 1px solid $grey-20;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: $grey-text;
    &:focus {
      box-shadow: unset;
      border: 1px solid $light-green-text-color;
    }
  }
  .input-group-caption {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $grey-50;
  }

  .PhoneInput {
    padding: 10px 18px;
    display: flex !important;
    .PhoneInputInput {
      border: none;

      &:focus-visible {
        outline: 0;
      }
    }
  }

  .submit-wrapper {
    position: fixed;
    bottom: 0;
    background-color: $grey-0;
    width: 100%;
    padding: 1.5rem 0 2.5rem;
    border-top: 2px solid $whisper-color;
    display: flex;
    gap: 10px;
  }
}

/* Buttons styles */

.btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 8px 20px;

  svg {
    margin: 6px;
  }

  &:disabled {
    background-color: $grey-5 !important;
    color: $grey-30 !important;
    border: transparent;
  }
}

.btn.large {
  padding: 12px 20px;
}

.btn.cta {
  background-color: $pine-green-primary;
  color: $white;

  &:hover {
    background-color: $pine-green-70 !important;
    color: $white !important;
  }
  &:active {
    background-color: $pine-green-80 !important;
    color: $white !important;
  }
  &:focus {
    background-color: $pine-green-primary !important;
    color: $white !important;
    box-shadow:
      0px 0px 0px 3px rgba(172, 197, 195, 1),
      0px 0px 0px 0.6px rgba(14, 46, 43, 0.4) !important;
  }
}

.btn.simple {
  background-color: $white;
  color: $grey-80;
  border-color: $grey-40;

  &:hover {
    background-color: $pine-green-0 !important;
    color: $grey-80 !important;
  }
  &:active {
    background-color: $pine-green-10 !important;
    color: $grey-80 !important;
  }
  &:focus {
    background-color: $white;
    color: $grey-80 !important;
    box-shadow:
      0px 0px 0px 3px rgba(172, 197, 195, 1),
      0px 0px 0px 0.6px rgba(14, 46, 43, 0.4) !important;
  }
}

.btn.flat {
  background-color: transparent;
  border-color: transparent;
  color: $grey-80;
  font-weight: 500;

  &:hover {
    background-color: $pine-green-0 !important;
    color: $grey-80 !important;
    border-color: $grey-40 !important;
  }
  &:active {
    background-color: $pine-green-10 !important;
    color: $grey-80 !important;
    border-color: $grey-40 !important;
  }
  &:disabled {
    background-color: transparent !important;
    color: $grey-30 !important;
  }
  &:focus {
    box-shadow:
      0px 0px 0px 3px rgba(172, 197, 195, 1),
      0px 0px 0px 0.6px rgba(14, 46, 43, 0.4) !important;
  }
}

.btn.cta-warning {
  background-color: $red-primary;
  border-color: $red-primary;
  color: $white;
  &:hover {
    background-color: $red-70 !important;
  }
  &:active {
    background-color: $red-80 !important;
  }
  &:focus {
    box-shadow:
      0px 0px 0px 3px rgba(226, 181, 193, 1),
      0px 0px 0px 0.6px rgba(228, 111, 140, 0.4) !important;
  }
}

.btn.simple-warning {
  background-color: $white;
  color: $red-primary;
  border-color: $red-40;

  &:hover {
    background-color: $red-10 !important;
    color: $red-primary;
  }
  &:active {
    background-color: $red-20 !important;
    color: $red-primary;
  }
  &:focus {
    box-shadow:
      0px 0px 0px 3px rgba(226, 181, 193, 1),
      0px 0px 0px 0.6px rgba(228, 111, 140, 0.4) !important;
  }
}

.btn.flat-warning {
  background-color: transparent;
  color: $red-primary;

  &:hover {
    background-color: $red-10 !important;
    border-color: $red-40 !important;
  }
  &:active {
    background-color: $red-20 !important;
    border-color: $red-40 !important;
  }
  &:disabled {
    background-color: $grey-5 !important;
    color: $grey-30 !important;
  }
  &:focus {
    background-color: $white !important;
    box-shadow:
      0px 0px 0px 3px rgba(226, 181, 193, 1),
      0px 0px 0px 0.6px rgba(228, 111, 140, 0.4) !important;
  }
}

.btn.btn-for-input {
  padding: 4px;
  border-radius: 4px;

  &:hover {
    background-color: $pine-green-0 !important;
  }
  &:active {
    background-color: $pine-green-10 !important;
  }
}

.tab-button {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $pine-green-primary;
  padding: 8px 6px;
  border-radius: 6px;
  border: none;
  background-color: transparent;
  transition: all 0.1s ease;

  &.active {
    background-color: $pine-green-primary;
    color: $white;
  }
}

.link {
  color: $light-green-text-color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $light-green-text-color;
    text-decoration: underline;
  }
}

/* Upload files style */

.upload-image-section {
  .upload-image-inner {
    background: $white;
    height: 100%;
    padding: 30px;
    min-height: 250px;
    border: 1px dashed #888b8e;
    border-radius: 10px;
    .upload-image-view {
      border-radius: 5px;
      width: 138px;
      height: 127px;
      cursor: pointer;
      position: relative;
      .overlay {
        display: none;
        background-color: rgba(32, 32, 32, 0.75);
        width: inherit;
        height: inherit;
        border-radius: 5px;
      }
      &:hover {
        .overlay {
          display: block;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .remove-txt {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: $white;
          }
        }
      }
      img {
        width: inherit;
        height: inherit;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .upload-image-icon-view {
      background: #c4c4c4;
      border-radius: 5px;
      width: 138px;
      height: 127px;
      .upload-icon-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $grey-text;
      }
    }
  }
  .image-max-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $grey-text;
  }
}

/* ImagePreview Style */

.image-preview-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .image-preview {
    width: 128px;
    height: 128px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/* Dropdown style */

.dropdown-list {
  padding: 0.5rem;
  border-radius: 10px;
  background-color: $white;
  box-shadow:
    0px 4px 8px 0px rgba(90, 96, 95, 0.1),
    0px 0px 3px 0px rgba(90, 96, 95, 0.28);
  z-index: 1000;
  .p-overlaypanel-content {
    padding: 0;
  }
  .dropdown-item {
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 6px;
    color: $grey-90;
    cursor: pointer;

    &:hover {
      background-color: $grey-5;
    }
  }
}

/* company info component */

.company-info {
  position: fixed;
  top: 50vh;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  .company-logo {
    height: 3rem;
  }
  .people-joined {
    display: flex;

    .users {
      display: flex;
      img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 1px solid $white;
        background-color: $white;
      }
      img:nth-child(2) {
        transform: translateX(-30%);
      }
      img:nth-child(3) {
        transform: translateX(-60%);
      }
    }
  }
  .login-title {
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
  }
}

/* Cards styles */

.count-card-wrapper {
  width: 100%;
  .count-card-icon {
    width: 50px;
    height: 50px;
  }
  .heading,
  .subheadingcount-card-subheading {
    display: none;
  }
  .count-card-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    .count-card-item {
      min-width: 368px;
      max-width: 480px;
      width: max-content;
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 20px;
      background-color: $white;
      border: 1px solid $grey-20;
      border-radius: 20px;

      .count-card-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .count-card-amount {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    display: none;
  }
}

.mobile-count-card-wrapper {
  display: none;
  flex-direction: column;
  padding: 20px;
  margin: 0;
  background-color: $white;
  border: 1px solid $grey-20;
  border-radius: 20px;

  .count-card-icon {
    height: 25px;
    width: 25px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
  }
  .count-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    .count-card-item {
      display: flex;

      .count-card-content {
        width: max-content;
        display: flex;
        flex-direction: column;
      }
      .last-row {
        width: max-content;
      }
    }
    .count-card-item.justify-content-end {
      .count-card-amount {
        text-align: end;
      }
    }
    .count-card-item.justify-content-center {
      .count-card-amount {
        text-align: center;
      }
    }
    .count-card-item.justify-content-start {
      .count-card-amount {
        text-align: start;
      }
    }
  }
  @media (max-width: 992px) {
    display: flex;
  }
}

.payment-status-card {
  display: flex;
  padding: 20px;
  gap: 20px;
  border-radius: 20px;
  border: 1px solid;

  .status-icon {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
    }
  }

  .wrapper-info {
    display: flex;
    flex-direction: column;
  }

  &.success {
    background-color: $pine-green-50;
    border-color: $pine-green-20;

    .caption {
      color: $white;
    }
  }
  &.warning {
    background-color: $yellow-50;
    border-color: $yellow-20;

    .caption {
      color: $yellow-100;
    }
  }
  &.danger {
    background-color: $red-50;
    border-color: $red-20;

    .caption {
      color: $red-100;
    }
  }
}

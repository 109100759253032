.tabMenu ul {
  justify-content: space-between !important;
  li {
    // A0A2A5
    .p-menuitem-text {
      color: #a0a2a5;
      margin-left: 10px !important;
    }
    a.p-menuitem-link {
      color: #02ad88 !important;
      &:not(.p-disabled):focus {
        box-shadow: none !important;
        color: #02ad88 !important;
      }
    }
  }
}
.tab {
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: $white;
    border-color: #02ad88 !important;
    width: 100%;
    display: flex;
    justify-content: center;
    .p-menuitem-text {
      color: #02ad88;
    }
  }
}

.tabMenuTenant ul {
  justify-content: space-around !important;
  li {
    // A0A2A5
    .p-menuitem-text {
      color: #a0a2a5;
      margin-left: 10px !important;
    }
    a.p-menuitem-link {
      color: #02ad88 !important;
      &:not(.p-disabled):focus {
        box-shadow: none !important;
        color: #02ad88 !important;
      }
    }
  }
}

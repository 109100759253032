.card.overviewCard {
  background: $white;
  box-shadow: 0px 2.98847px 4.48271px rgba(62, 73, 84, 0.04);
  border-radius: 15px;

  .heading {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: $light-green-text-color;
    &.overview-stripe {
      font-weight: 600;
      color: #000000;
    }
  }
  .subHeading {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $light-green-text-color;
    &.overview-stripe {
      color: #000000;
    }
  }
  .paymentCard {
    background-color: $white;
    border-radius: 14.9424px;
    width: 67.24px;
    height: 67.24px;
  }
  .paymentCardDetails {
    padding: 15px;
    background: #eeeeee;
    border-radius: 15px;
  }
}
.card.add-unit-connect-card {
  background-color: $light-green-text-color;
  box-shadow: 0px 2.98847px 4.48271px rgba(62, 73, 84, 0.04);
  border-radius: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $white;
}
//paymentOverview styles
.card.overviewPaymentCard {
  background-color: $secondary-bg-color;
  border-radius: 14.942px;
  box-shadow: 0px 2.9884746074676514px 4.4827117919921875px 0px rgba(62, 73, 84, 0.04);
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: $white;
  }
  .subHeading {
    font-weight: 400;
    font-size: 20px;
    color: $white;
  }
  .paymentCardDetails {
    padding: 17px 18px 17px 18px;
    background: $white;
    border-radius: 15px;
  }
}

.circle-overview-chart {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

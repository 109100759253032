.tenant-detail {
  .tenant {
    width: 220px;
    height: 220px;
    padding: 10px;
    background-color: $white;
    border-radius: 20px;
    margin-top: -60px;
    box-shadow: 0px 4px 6px rgba(62, 73, 84, 0.04);

    .tenant-img {
      width: 200px;
      height: 200px;
      background-color: #f4f7f7;
      border-radius: 10px;
    }
  }
  .tenant-name {
    padding: 20px 15px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 27px;
    text-transform: capitalize;

    .badge {
      width: 80px;
      margin-left: 15px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: $white;
      border-radius: 5px;
      padding: 0;
      &.bg-success.badge-success {
        background-color: $light-green-secondary-btn-color !important;
      }
      &.badge.bg-danger.badge-danger {
        background-color: #d02f44 !important;
      }
    }
  }
}

.features-list {
  max-width: 400px;
  overflow: hidden;
  display: flex;
  gap: 6px;
}

.remove-selection-button {
  background-color: $white;
  border: 1px solid #888b8e;
  border-radius: 6px;
  width: max-content !important;
  padding: 0.2rem 0.6rem !important;
  text-align: center;
}

.features-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  min-height: 4rem;
}

.feature-card {
  background-color: $white;
  width: 256px;
  height: max-content;
  padding: 0.75rem;
  border-radius: 10px;
  border: 1px solid $grey-20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;

  .feature-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $grey-90;
  }
  img {
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}

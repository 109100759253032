.verification-wrapper {
    .verify-account-heading {
        padding-top: 28px;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 58px;
        color: $green-text-color;
    }
    .verify-email-text {
        font-style: normal;
        font-weight: 500;
        font-size: 38px;
        line-height: 46px;
        color: $green-text-color;
    }
    .continue-text {
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 46px;
        color: #000000;
    }
    .email-text {
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 28px;
        color: #000000;
    }
    .submit-btn {
        font-weight: 500;
        font-size: 19px;
        line-height: 28px;
    }
    .trouble-verifying {
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        color: $link-primary;
        cursor: pointer;
    }
}